import { Injectable } from '@angular/core'

import { Observable, map } from 'rxjs'

import {
  GetMaskedKeysGQL,
  GetAutoAssessmentSettingGQL,
  SaveAmptalkKeyGQL,
  ConnectService,
  SecretKeyType,
  SaveAutoAssessmentSettingGQL,
  DisconnectAmptalkGQL,
  DisconnectZoomGQL,
  SaveZoomTokenGQL,
  CheckSecretKeyExistenceGQL,
} from '../../../_generated/graphql'

import {
  mappingAutoAssessmentSetting,
  AutoAssessmentSetting,
} from './external-integration.mapping'

@Injectable({
  providedIn: 'root',
})
export class ExternalIntegrationService {
  constructor(
    private getMaskedKeysGQL: GetMaskedKeysGQL,
    private getAutoAssessmentSettingGQL: GetAutoAssessmentSettingGQL,
    private saveAmptalkKeyGQL: SaveAmptalkKeyGQL,
    private saveZoomTokenGQL: SaveZoomTokenGQL,
    private saveAutoAssessmentSettingGQL: SaveAutoAssessmentSettingGQL,
    private disconnectAmptalkGQL: DisconnectAmptalkGQL,
    private disconnectZoomGQL: DisconnectZoomGQL,
    private CheckSecretKeyExistenceGQL: CheckSecretKeyExistenceGQL,
  ) {}

  getAutoAssessmentSetting(
    connectService: ConnectService,
  ): Observable<AutoAssessmentSetting | null> {
    return this.getAutoAssessmentSettingGQL
      .fetch({
        connectService,
      })
      .pipe(
        map((res) => {
          return mappingAutoAssessmentSetting(res.data.getAutoAssessmentSetting)
        }),
      )
  }

  getAmptalkKeys(): Observable<AmptalkKeys> {
    return this.getMaskedKeysGQL
      .fetch({
        connectService: ConnectService.AmpTalk,
      })
      .pipe(
        map((res) => {
          const maskedKeys = res.data.getMaskedKeys
          const apiKey =
            maskedKeys.find((key) => key.keyType === SecretKeyType.AmptalkApi)
              ?.maskedKey || ''
          const authKey =
            maskedKeys.find((key) => key.keyType === SecretKeyType.AmptalkAuth)
              ?.maskedKey || ''
          return {
            apiKey,
            authKey,
          }
        }),
      )
  }

  checkSecretKeyExistence(secretKeyType: SecretKeyType): Observable<boolean> {
    return this.CheckSecretKeyExistenceGQL.fetch({ secretKeyType }).pipe(
      map((res) => res.data.checkSecretKeyExistence),
    )
  }

  saveAmptalkKey(apiKey: string, authKey: string) {
    return this.saveAmptalkKeyGQL.mutate({ apiKey, authKey })
  }

  saveZoomToken(code: string) {
    return this.saveZoomTokenGQL.mutate({ code })
  }

  saveAssessmentSetting(
    setting: SaveAutoAssessmentSettingParam,
    connectServiceName: ConnectService,
  ) {
    return this.saveAutoAssessmentSettingGQL.mutate({
      param: {
        ...setting,
        connectServiceName,
      },
    })
  }

  disconnectAmptalk() {
    return this.disconnectAmptalkGQL.mutate()
  }

  disconnectZoom() {
    return this.disconnectZoomGQL.mutate()
  }
}

export interface TargetUser {
  id: string
  name: string
  email: string
  departmentName?: string | null
  thumbnailPath?: string | null
  reportTo?: {
    id: string
    name: string
  } | null
  license?: {
    id: string
    plan: string
    userMonthlyLimitSeconds: number
    extraChargeLimitSeconds: number
    usedSeconds: number
  } | null
}

export interface AmptalkKeys {
  apiKey: string
  authKey: string
}

export interface SaveAutoAssessmentSettingParam {
  id?: string
  syncStartDate: Date
  skillMapId: string
  phaseIds: string[]
  targetUserIds: string[]
  minTransferDurationSeconds: number | null
  whiteList: string[]
  blackList: string[]
}
