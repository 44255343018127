import { CommonModule } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { MatDialog } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltip } from '@angular/material/tooltip'
import { MatSnackBar } from '@angular/material/snack-bar'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { marked } from 'marked'
import {
  Subscription,
  catchError,
  filter,
  lastValueFrom,
  of,
  switchMap,
  tap,
  timer,
} from 'rxjs'

import { RenameBusinessMeetingComponent } from './rename-business-meeting/rename-business-meeting.component'
import { FileInformationInputComponent } from './file-information-input/file-information-input.component'
import { FileInformationViewComponent } from './file-information-view/file-information-view.component'
import { AssessmentStatus } from '../../../../_generated/graphql'
import { AvatarComponent } from '../../../components/avatar/avatar.component'
import { ButtonComponent } from '../../../components/button/button.component'
import { LoadingComponent } from '../../../components/loading/loading.component'
import {
  DialogResult,
  MessageDialogService,
} from '../../../components/message-dialog/message-dialog.service'
import {
  Option,
  SelectComponent,
} from '../../../components/select/select.component'
import {
  Assessment,
  KeyActionEvaluation,
  SkillMap,
} from '../../../services/assessment/assessment.mapping'
import { AuthService } from '../../../services/auth.service'
import { User } from '../../../services/user/user.mapping'
import { AssessmentStore } from '../../../stores/assessment.store'
import { UserStore } from '../../../stores/user.store'
import { SUPPORTED_AUDIO_EXTENSIONS } from '../../../util/file/constants'
import { getMimeType } from '../../../util/file/encoding'
import { environment } from '../../../../environments/environment'
import { StorageService } from '../../../services/storage.service'
import { getTitle } from '../../../util/accessibility'
import { CsvExportService } from '../../../services/csv-export/csv-export.service'
import { ContextMenuComponent } from '../../../components/context-menu/context-menu.component'

@UntilDestroy()
@Component({
  selector: 'app-assessment-home',
  standalone: true,
  imports: [
    ButtonComponent,
    MatIcon,
    MatSlideToggleModule,
    MatTooltip,
    SelectComponent,
    CommonModule,
    LoadingComponent,
    AvatarComponent,
    ContextMenuComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  @ViewChild('evaluation') evaluation?: ElementRef<HTMLDivElement>
  @ViewChild('transcript') transcript?: ElementRef<HTMLDivElement>
  @ViewChild('audioPlayer') audioPlayerRef?: ElementRef<HTMLAudioElement>

  selectAssessmentSubscription: Subscription | null = null
  assessments: Option[] = []
  selectedAssessment: Option = { id: 'none', name: '商談未設定' }

  user: User | null = null
  assessment: Assessment | null = null
  selectedKeyAction: KeyActionEvaluation | null = null
  score = 0
  keyActionEvaluations: KeyActionEvaluation[] = []

  skillMaps: SkillMap[] = []
  accounts: { name: string }[] = []

  showSummary = false
  collapsed = false

  downloadUrl: string | null = null

  contextMenu = [
    {
      icon: 'delete',
      title: '削除',
      type: 'DELETE',
    },
  ]

  constructor(
    private sanitizer: DomSanitizer,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userStore: UserStore,
    private assessmentStore: AssessmentStore,
    private authService: AuthService,
    private messageDialogService: MessageDialogService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private csvExportService: CsvExportService,
  ) {
    this.titleService.setTitle(getTitle('ホーム'))
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        ;(
          await this.initializeHome(
            this.activatedRoute.snapshot.queryParamMap.get('assessment-id'),
          )
        ).subscribe()
      })
    this.assessmentStore.getSkillMaps().subscribe((skillMaps) => {
      this.skillMaps = skillMaps
    })
    this.assessmentStore.getAccounts().subscribe((accounts) => {
      this.accounts = accounts
    })
  }

  get highLights() {
    return this.keyActionEvaluations
      .slice()
      .filter(this.isHighlight)
      .sort((a, b) => b.score - a.score)
      .slice(0, 3)
  }

  get lowLights() {
    return this.keyActionEvaluations
      .slice()
      .filter(this.isLowLight)
      .sort((a, b) => a.score - b.score)
      .slice(0, 3)
  }

  isHighlight(evaluation: KeyActionEvaluation) {
    return evaluation.score >= 3
  }

  isLowLight(evaluation: KeyActionEvaluation) {
    return evaluation.score < 3
  }

  startUploadingFile() {
    if (
      this.matDialog.openDialogs.some(
        (dialog) => dialog.id === 'file-information-input',
      )
    ) {
      return
    }
    this.openFileInformationInput()
  }

  openFileInformationInput() {
    const dialogRef = this.matDialog.open(FileInformationInputComponent, {
      id: 'file-information-input',
      disableClose: true,
      panelClass: ['unset-mat-dialog-padding'],
      enterAnimationDuration: '0ms',
    })

    dialogRef.componentInstance.user = this.user ?? null
    dialogRef.componentInstance.skillMaps = this.skillMaps
    dialogRef.componentInstance.accounts = this.accounts
    dialogRef.componentInstance.userLicense = this.user?.license ?? null

    dialogRef.afterClosed().subscribe(async (assessmentId) => {
      if (!assessmentId) return

      this.assessment = null
      this.selectAssessmentSubscription?.unsubscribe()
      this.router.navigate(['/home'], {
        queryParams: { 'assessment-id': assessmentId },
        queryParamsHandling: 'merge',
      })
      this.assessmentStore.getAccounts().subscribe((accounts) => {
        this.accounts = accounts
      })
    })
  }

  isSelfUser(): boolean {
    return this.user?.id === this.authService.user?.id
  }

  isDirectManager(): boolean {
    return this.user?.reportToUserId === this.authService.user?.id
  }

  isEnablerOrAdmin(): boolean {
    return (
      this.userStore
        .ctxUser()
        ?.roles.some(
          (role) => role.code === 'ENABLER' || role.code === 'SUPER_ADMIN',
        ) ?? false
    )
  }

  hasLicense(): boolean {
    return this.user?.license != null
  }

  parseMarkdown(markdown?: string | null) {
    if (!markdown) return ''
    const html = marked(markdown) as string
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  copyToClipboard(text: string | null) {
    if (!text) return
    navigator.clipboard.writeText(text)
    this.snackBar.open('コピーしました', '閉じる', {
      duration: 2000,
    })
  }

  showMore(type: 'HIGH_LIGHTS' | 'LOW_LIGHTS') {
    console.log(type)
    alert('not implemented')
  }

  showRenameBusinessMeeting() {
    const dialogRef = this.matDialog.open(RenameBusinessMeetingComponent, {
      id: 'rename-business-meeting',
      disableClose: true,
      panelClass: ['unset-mat-dialog-padding'],
      autoFocus: false,
    })

    dialogRef.componentInstance.data = {
      assessmentId: this.assessment?.id ?? '',
      name: this.assessment?.businessMeeting.title ?? '',
    }

    dialogRef.afterClosed().subscribe((newName) => {
      if (newName) {
        const newAssessments = this.assessments.map((assessment) => {
          if (assessment.id === this.assessment?.id) {
            return { id: assessment.id, name: newName }
          }
          return assessment
        })
        this.assessments = newAssessments
      }
    })
  }

  showRegisterInformation() {
    const dialogRef = this.matDialog.open(FileInformationViewComponent, {
      id: 'file-information-view',
      disableClose: true,
      panelClass: ['unset-mat-dialog-padding'],
      enterAnimationDuration: '0ms',
    })

    dialogRef.componentInstance.businessMeetingName =
      this.assessment?.businessMeeting?.title ?? null
    dialogRef.componentInstance.accountName =
      this.assessment?.businessMeeting?.account?.name ?? null
    dialogRef.componentInstance.skillMapName =
      this.assessment?.skillMapName ?? ''
    dialogRef.componentInstance.phaseName =
      this.assessment?.businessMeeting.applicablePhaseNames?.join(', ') ?? ''
    dialogRef.componentInstance.recordDate =
      this.assessment?.businessMeeting?.recordDate ?? null
    const path = this.assessment?.businessMeeting?.filePath.split('/')
    dialogRef.componentInstance.fileName = path
      ? (path[path.length - 1] ?? null)
      : null
    dialogRef.componentInstance.reference =
      this.assessment?.businessMeeting?.reference ?? null
    const durationSeconds = this.assessment?.durationSeconds ?? 0
    dialogRef.componentInstance.durationTIme = durationSeconds
      ? `${String(Math.floor(durationSeconds / 3600)).padStart(2, '0')}:${String(Math.floor((durationSeconds % 3600) / 60)).padStart(2, '0')}:${String(durationSeconds % 60).padStart(2, '0')}`
      : '00:00:00'
  }

  toggleSummary() {
    this.showSummary = !this.showSummary
  }

  selectKeyAction(evaluationId: string) {
    this.showSummary = false
    this.selectedKeyAction =
      this.assessment?.keyActionEvaluations.find(
        (e) => e.id === evaluationId,
      ) ?? null
    this.cdr.detectChanges()

    setTimeout(() => {
      if (this.selectedKeyAction) {
        this.scrollEvaluationToTop()
        this.scrollToHighlight(this.selectedKeyAction.highlight)
      }
    })
  }

  changeAssessment(event: { id: string }) {
    if (!this.user) {
      return
    }

    if (event.id === this.assessment?.id) return

    this.assessment = null
    this.selectAssessmentSubscription?.unsubscribe()
    this.router.navigate(['/home'], {
      queryParams: { 'assessment-id': event.id },
      queryParamsHandling: 'merge',
    })
  }

  private selectAssessment(assessment: {
    id: string
    status: AssessmentStatus
  }) {
    this.downloadUrl = null
    const timer$ =
      assessment.status === 'COMPLETED' || assessment.status === 'FAILED'
        ? of(0)
        : timer(0, 5000)

    return timer$.pipe(
      untilDestroyed(this),
      switchMap(() => {
        return this.assessmentStore.getAssessment(assessment.id).pipe(
          tap((assessment) => {
            this.assessment = assessment

            if (this.isMediaFile()) {
              this.storageService
                .getDownloadUrl(this.assessment?.businessMeeting.filePath)
                .subscribe((url) => {
                  this.downloadUrl = url
                  setTimeout(() => {
                    this.audioPlayerRef?.nativeElement.load()
                  }, 200)
                })
            }

            this.score =
              assessment.keyActionEvaluations.reduce(
                (acc, keyActionEvaluation) => acc + keyActionEvaluation.score,
                0,
              ) / assessment.keyActionEvaluations.length
            this.keyActionEvaluations = assessment.keyActionEvaluations
            if (
              assessment.status === 'COMPLETED' ||
              assessment.status === 'FAILED'
            ) {
              this.cdr.detectChanges()
            }
            if (assessment.status === 'COMPLETED') {
              this.loadUser(this.user?.id ?? '')
            }
            if (assessment.businessMeeting.summary) {
              this.showSummary = true
            } else {
              this.showSummary = false
              if (this.keyActionEvaluations.length > 0) {
                this.selectedKeyAction = this.keyActionEvaluations[0]
                setTimeout(() => {
                  if (this.selectedKeyAction) {
                    this.scrollToHighlight(this.selectedKeyAction.highlight)
                  }
                })
              }
            }
            if (this.hasTimeSeriesEvaluations()) {
              this.contextMenu = [
                {
                  icon: 'download',
                  title: '時間帯別評価ダウンロード',
                  type: 'DOWNLOAD',
                },
                {
                  icon: 'edit',
                  title: '名前を変更',
                  type: 'RENAME',
                },
                { icon: 'delete', title: '削除', type: 'DELETE' },
              ]
            } else {
              this.contextMenu = [
                {
                  icon: 'edit',
                  title: '名前を変更',
                  type: 'RENAME',
                },
                { icon: 'delete', title: '削除', type: 'DELETE' },
              ]
            }
          }),
        )
      }),
    )
  }

  async initializeHome(assessmentId: string | null = null) {
    let userId = this.activatedRoute.snapshot.queryParamMap.get('user-id')
    if (!userId) {
      userId =
        (await lastValueFrom(this.userStore.getOneSelf({ useCache: true })))
          ?.id ?? null
    }
    if (!userId) {
      alert('authenticated user not found')
      throw new Error('authenticated user not found')
    }
    return this.userStore.getUser(userId).pipe(
      tap((user) => {
        this.user = user

        if (user.assessments.length === 0) {
          return of([])
        }

        this.assessments = user.assessments
          .sort((a, b) => b.assessDate.getTime() - a.assessDate.getTime())
          .map((assessment) => ({
            id: assessment.id,
            name: assessment.businessMeeting.title,
          }))

        const assessId = assessmentId ?? this.user.assessments[0].id

        this.selectedAssessment = this.assessments.find(
          (assessment) => assessment.id === assessId,
        ) ?? { id: 'none', name: '商談未設定' }

        this.titleService.setTitle(
          getTitle(
            `アセスメント（${this.user.name} | ${this.selectedAssessment.name}）`,
          ),
        )

        this.selectAssessmentSubscription = this.selectAssessment(
          this.user.assessments.find(
            (assessment) => assessment.id === assessId,
          ) ?? this.user.assessments[0],
        ).subscribe((assessment) => {
          if (
            assessment.status === 'COMPLETED' ||
            assessment.status === 'FAILED'
          ) {
            this.selectAssessmentSubscription?.unsubscribe()
          }
        })

        return
      }),
    )
  }

  async markContentAsAccessed(recommendedContentId: string) {
    if (!this.user || this.user.id !== this.authService.user?.id) {
      return
    }
    this.assessmentStore
      .markContentAsAccessed(recommendedContentId)
      .pipe(
        tap(() => {
          const content = this.selectedKeyAction?.recommendedContents.find(
            (c) => c.id === recommendedContentId,
          )
          if (content) {
            content.isAccessed = true
          }
        }),
      )
      .subscribe()
  }

  isNotSet() {
    return this.selectedAssessment.id === 'none'
  }

  deleteAssessment(assessment: Assessment) {
    this.messageDialogService
      .showConfirm(
        `
      「${assessment.businessMeeting.title}」のアセスメントを削除してもよろしいですか？
      この操作は取り消すことができません。
      `,
        {
          primaryButtonText: '削除',
          secondaryButtonText: 'キャンセル',
        },
      )
      .pipe(
        filter((result) => result === DialogResult.PrimaryButtonClicked),
        switchMap(() => {
          this.assessment = null
          this.selectedAssessment = { id: 'none', name: '商談未設定' }
          return this.assessmentStore.deleteAssessment(assessment.id).pipe(
            catchError(() => {
              this.messageDialogService
                .showError(
                  'アセスメントの削除に失敗しました。時間をおいて再度お試しください。',
                )
                .subscribe()
              return of()
            }),
          )
        }),
        tap(async () => {
          // remove assessment id from query parameter
          await this.router.navigate(['/home'], {
            queryParams: {
              'user-id': this.user?.id,
            },
          })
        }),
        switchMap(() => {
          // refresh assessment list and select default assessment
          return this.initializeHome()
        }),
      )
      .subscribe()
  }

  isMediaFile() {
    return SUPPORTED_AUDIO_EXTENSIONS.some((ext) =>
      this.assessment?.businessMeeting?.filePath?.toLowerCase()?.endsWith(ext),
    )
  }

  getMediaFileUrl() {
    return `https://${environment.firebase.storageBucket}/${this.assessment?.businessMeeting?.filePath}`
  }

  seekTo(speakedAt: string) {
    if (this.audioPlayerRef) {
      const timeInSeconds = this.convertToSeconds(speakedAt)
      const audioPlayer = this.audioPlayerRef.nativeElement
      audioPlayer.onseeked = () => {
        audioPlayer.play()
        audioPlayer.onseeked = null
      }
      audioPlayer.currentTime = timeInSeconds
    }
  }

  getMimeType(url: string) {
    const extension = url.split('?').shift()?.split('.')?.pop()
    if (!extension) {
      return 'audio/mpeg'
    }
    return getMimeType(extension)
  }

  contextMenuClick(event: string) {
    switch (event) {
      case 'DOWNLOAD':
        this.exportTimeSeriesEvaluations()
        break
      case 'RENAME':
        this.showRenameBusinessMeeting()
        break
      case 'DELETE':
        if (this.assessment) {
          this.deleteAssessment(this.assessment)
        }
        break
    }
  }

  hasTimeSeriesEvaluations() {
    return this.assessment?.keyActionEvaluations.some(
      (evaluation) => evaluation.timeSeries.length > 0,
    )
  }

  exportTimeSeriesEvaluations() {
    if (!this.assessment) {
      return
    }
    const data = this.assessment.keyActionEvaluations.flatMap((evaluation) => {
      return evaluation.timeSeries.map((ts, index) => {
        return {
          keyActionName: evaluation.keyAction.name,
          calculationMethod:
            evaluation.keyAction.assessmentQuestionType === 'SCALE'
              ? '最大'
              : '平均',
          timeSeriesIndex: index + 1,
          fromTime: ts.fromTime,
          toTime: ts.toTime,
          score: ts.score,
          reason: ts.reason,
          strength: ts.strength,
          improvement: ts.improvement,
          quote: ts.quote,
        }
      })
    })
    this.csvExportService.downloadCsv(
      this.assessment?.businessMeeting.title + '_時間帯別評価.csv',
      [
        { label: 'キーアクション', key: 'keyActionName' },
        { label: '時間帯番号', key: 'timeSeriesIndex' },
        { label: '開始', key: 'fromTime' },
        { label: '終了', key: 'toTime' },
        { label: 'スコア', key: 'score' },
        { label: '理由', key: 'reason' },
        { label: '良かった点', key: 'strength' },
        { label: '改善したい点', key: 'improvement' },
        { label: '参照箇所', key: 'quote' },
        { label: '評価算出方法', key: 'calculationMethod' },
      ],
      data,
    )
  }

  private convertToSeconds(timeString: string): number {
    const parts = timeString.split(':').map((part) => parseInt(part, 10))

    let seconds = 0

    if (parts.length === 3) {
      const [hours, minutes, secs] = parts
      seconds = hours * 3600 + minutes * 60 + secs
    } else if (parts.length === 2) {
      const [minutes, secs] = parts
      seconds = minutes * 60 + secs
    }

    return seconds
  }

  private scrollEvaluationToTop() {
    if (!this.evaluation) return
    this.evaluation.nativeElement.scrollTo(0, 0)
  }

  private scrollToHighlight(highlight: string) {
    if (!this.transcript) return
    const elements =
      this.transcript.nativeElement.querySelectorAll('.speaked-at')
    const element = Array.from(elements).find(
      (el) => el.textContent === highlight,
    )

    if (element) {
      const parent = element.parentElement
      parent?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  private loadUser(userId: string) {
    this.userStore.getUser(userId).subscribe((user) => {
      this.user = user
    })
  }
}
