<div class="dialog-container">
  <app-loading [showLoading]="isLoading"></app-loading>
  <div class="header">
    <div class="assessment-setting">
      <button
        type="button"
        mat-icon-button
        class="close-button"
        (click)="closeSettingDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <h1 class="heading">
        {{ this.displayConnectServiceName }}アセスメント設定
      </h1>
    </div>
    <p class="caption">
      {{
        this.displayConnectServiceName
      }}アセスメントの設定が行えます。スキルマップとフェーズや対象ユーザーを選択してください。
    </p>
  </div>
  <div class="form dialog-content">
    <div class="field">
      <div class="input-with-error">
        <label class="label" for="skill-map">スキルマップ *</label>
        <div class="value">
          <app-select
            id="skill-map"
            [options]="this.skillMapOptions"
            (selectedOptionChange)="this.changeSkillMap($event)"
            [selectedOption]="this.selectedSkillMap"
            [showError]="isShowError && !this.skillMapSelected()"
          ></app-select>
        </div>
        <div
          class="input-error"
          *ngIf="isShowError && !this.skillMapSelected()"
        >
          スキルマップが選択されていません
        </div>
      </div>
    </div>
    <div class="field">
      <div class="input-with-error">
        <label class="label" for="applicable-phases">フェーズ *</label>
        <div class="value">
          <app-multiple-select
            id="applicable-phases"
            [items]="this.phaseItems"
            (changeSelected)="changeSelectedPhases($event)"
            [showError]="isShowError && !this.phaseSelected()"
          ></app-multiple-select>
        </div>
        <div class="input-error" *ngIf="isShowError && !this.phaseSelected()">
          フェーズが選択されていません
        </div>
      </div>
    </div>
    <div class="field label-fixed">
      <div class="value">
        <label class="label" for="applicable-phases">対象ユーザー *</label>
        <div class="user-list">
          <div class="table-container">
            <table mat-table [dataSource]="this.users">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    [checked]="this.checkedState === 'CHECKED'"
                    [indeterminate]="this.checkedState === 'INDETERMINATE'"
                    (change)="this.checkAllUsers($event.checked)"
                  ></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let user">
                  <mat-checkbox
                    [disabled]="!user.hasLicense"
                    [checked]="user.selected"
                    (change)="this.checkChanged(user)"
                  ></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>名前</th>
                <td mat-cell *matCellDef="let user">
                  <div class="user-info">
                    <div class="avatar">
                      <app-avatar
                        [thumbnailUrl]="user.thumbnailPath ?? ''"
                      ></app-avatar>
                    </div>
                    <div class="user-name-email">
                      <div
                        class="name"
                        [matTooltip]="user.name"
                        appEllipsisTooltip
                      >
                        {{ user.name }}
                      </div>
                      <div
                        class="email"
                        [matTooltip]="user.email"
                        appEllipsisTooltip
                      >
                        {{ user.email }}
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="userDepartmentName">
                <th mat-header-cell *matHeaderCellDef>部署</th>
                <td mat-cell *matCellDef="let user">
                  <div [matTooltip]="user.departmentName" appEllipsisTooltip>
                    {{ user.departmentName }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="reportTo">
                <th mat-header-cell *matHeaderCellDef>上司</th>
                <td mat-cell *matCellDef="let user">
                  <div [matTooltip]="user.reportTo ?? ''" appEllipsisTooltip>
                    {{ user.reportTo ?? '' }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="remainingHours">
                <th mat-header-cell *matHeaderCellDef>残りの利用時間</th>
                <td mat-cell *matCellDef="let user">
                  {{ user.hasLicense ? user.remainingHours + '時間' : '' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="license">
                <th mat-header-cell *matHeaderCellDef>ライセンス</th>
                <td mat-cell *matCellDef="let user">
                  <span class="chip" [class.disabled]="!user.hasLicense">
                    {{ user.hasLicense ? 'あり' : 'なし' }}
                  </span>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [class.disabled]="!row.hasLicense"
              ></tr>
            </table>
            <ng-container *ngIf="this.users.length === 0">
              <div class="empty">該当なし</div>
            </ng-container>
          </div>
        </div>
        <div class="input-error" *ngIf="isShowError && !this.userSelected()">
          対象ユーザーが選択されていません
        </div>
      </div>
    </div>
    <div class="field">
      <div class="input-with-error">
        <label class="label" for="min-transfer-duration"
          >最小連携対象時間（分）</label
        >
        <div class="value">
          <input
            type="text"
            inputmode="numeric"
            id="min-transfer-duration"
            class="input"
            [value]="this.minTransferDurationMinutes"
            [class.error]="isShowError && !this.isValidMinTransferDuration()"
            placeholder="最小連携対象時間を入力してください"
            (change)="changeMinimumIntegrationDuration($event)"
          />
        </div>
        <div
          class="input-error"
          *ngIf="isShowError && !this.isValidMinTransferDuration()"
        >
          最小連携対象時間の形式が正しくありません
        </div>
      </div>
    </div>

    <div class="field recording-target-list" *ngIf="this.data.enableWordFilter">
      <div class="recording-target-header">
        <div class="label">
          対象レコーディング
          <mat-icon
            class="info-icon"
            matTooltip="入力したキーワードがZoomレコーディング名に「含まれる」または「含まれない」場合の評価対象を選択できます。"
            matTooltipPosition="above"
          >
            info
          </mat-icon>
        </div>
      </div>

      <!-- 入力専用の行 -->
      <div class="form-field vertical-layout">
        <div class="value recording-target-group">
          <app-select
            [options]="recordingTargetOptions"
            [selectedOption]="recordingTargetInputSelect"
            (selectedOptionChange)="onChangeRecordingTargetInputSelect($event)"
          ></app-select>
          <input
            type="text"
            inputmode="numeric"
            class="input"
            placeholder="キーワードを記入"
            [value]="recordingTargetInputKeyword"
            (input)="onChangeRecordingTargetInputKeyword($event)"
            [class.error]="isRecordingTargetInputKeywordError"
          />
          <button
            rectangle
            type="button"
            class="add-button"
            (click)="onAddRecordingTarget()"
          >
            <mat-icon>add</mat-icon>
            追加
          </button>
        </div>
        <div class="input-error" *ngIf="isRecordingTargetInputKeywordError">
          キーワードを入力してください。
        </div>
      </div>

      <!-- 追加された値の一覧 -->
      <div class="form-field vertical-layout">
        <ng-container *ngFor="let target of recordingTargets; let i = index">
          <div class="value recording-target-group">
            <app-select
              id="recording-targets-{{ i }}"
              [options]="recordingTargetOptions"
              [selectedOption]="target.selectedOption"
              (selectedOptionChange)="onChangeRecordingTarget(i, $event)"
            ></app-select>
            <input
              type="text"
              inputmode="numeric"
              class="input"
              placeholder="キーワードを記入"
              [value]="target.keyword"
              (input)="onKeywordChange(i, $event)"
            />
            <button type="button" (click)="removeRecordingTarget(i)">
              <mat-icon class="delete-icon">delete</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="footer">
    <button
      type="button"
      rectangle
      variant="secondary"
      (click)="closeSettingDialog()"
    >
      キャンセル
    </button>
    <button rectangle variant="primary" (click)="onAssessmentSettingSave()">
      保存
    </button>
  </div>
</div>
