import { ApolloError } from '@apollo/client/errors'
import { GraphQLErrorExtensions } from 'graphql/error'

export enum CustomErrorCode {
  DUPLICATED_USER = 'DUPLICATED_USER',
  MANAGER_RELATION_LOOP = 'MANAGER_RELATION_LOOP',
  FAILED_FETCH_ZOOM_USER = 'FAILED_FETCH_ZOOM_USER',
}

export class CustomError extends Error {
  code: CustomErrorCode
  extensions: GraphQLErrorExtensions

  constructor(
    code: CustomErrorCode,
    extensions: GraphQLErrorExtensions,
    message: string,
  ) {
    super()
    this.code = code
    this.extensions = extensions
    this.message = message
  }
}

export const throwIfCustomError = (error: unknown) => {
  if (!error) {
    return
  }

  if (!(error instanceof ApolloError)) {
    return
  }

  if (!error.graphQLErrors.length) {
    return
  }

  const index = error.graphQLErrors.findIndex((err) =>
    Object.values(CustomErrorCode).includes(
      err.extensions?.code as CustomErrorCode,
    ),
  )
  if (index !== -1) {
    const err = error.graphQLErrors[index]
    const extensions = err.extensions as GraphQLErrorExtensions
    throw new CustomError(
      extensions.code as CustomErrorCode,
      extensions,
      err.message,
    )
  } else {
    throw new Error(error.graphQLErrors[0].message)
  }
}
