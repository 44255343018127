import { CommonModule } from '@angular/common'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'

import { MatIcon } from '@angular/material/icon'
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'

import { forkJoin, of } from 'rxjs'
import { tap, switchMap, catchError } from 'rxjs/operators'

import { ButtonComponent } from '../../../components/button/button.component'

import {
  AmptalkIntegrationComponent,
  AmptalkIntegrationData,
} from './amptalk-integration/amptalk-integration.component'
import { IntegrationSuccessComponent } from './messages/integration-success/integration-success.component'
import { IntegrationResetWarningComponent } from './messages/integration-reset-warning/integration-reset-warning.component'
import { ExternalIntegrationService } from '../../../services/external-integration/external-integration.service'

import { getTitle } from '../../../util/accessibility'
import { environment } from '../../../../environments/environment'
import { ConnectService, SecretKeyType } from '../../../../_generated/graphql'
import {
  AutoAssessmentSettingDialogParam,
  SettingDialogComponent,
} from './setting-dialog/setting-dialog.component'

@Component({
  selector: 'app-external-integration-page',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatSlideToggle,
    MatTableModule,
    ButtonComponent,
  ],
  templateUrl: './external-integration-page.component.html',
  styleUrl: './external-integration-page.component.scss',
})
export class ExternalIntegrationPageComponent implements OnInit, OnDestroy {
  isAmptalkIntegrationEnabled = false
  hasAmptalkIntegrationError = false
  isZoomIntegrationEnabled = false
  hasZoomIntegrationError = false

  isShowError = false
  isLoading = false

  amptalkSetting: {
    apiKey: string
    authKey: string
  } = {
    apiKey: '',
    authKey: '',
  }

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private externalIntegrationService: ExternalIntegrationService,
  ) {
    this.titleService.setTitle(getTitle('外部連携'))
  }

  ngOnInit() {
    this.isLoading = true

    const amptalkKeys$ = this.externalIntegrationService.getAmptalkKeys().pipe(
      tap((keys) => {
        this.amptalkSetting = keys
        this.isAmptalkIntegrationEnabled = !!keys.apiKey || !!keys.authKey
        this.hasAmptalkIntegrationError = !!keys.apiKey && !keys.authKey
      }),
      catchError((error) => {
        console.error('Failed to get Amptalk keys:', error)
        return of(null)
      }),
    )

    const zoomIntegration$ = this.externalIntegrationService
      .checkSecretKeyExistence(SecretKeyType.ZoomRefreshToken)
      .pipe(
        tap((result) => {
          this.isZoomIntegrationEnabled = result
        }),
        catchError((error) => {
          console.error('Failed to check Zoom secret key existence:', error)
          return of(false)
        }),
      )

    forkJoin([amptalkKeys$, zoomIntegration$]).subscribe({
      next: () => {
        this.isLoading = false
      },
      error: (error) => {
        console.error('Failed to initialize integrations:', error)
        this.isLoading = false
      },
    })
  }

  ngOnDestroy() {
    this.snackBar.dismiss()
  }

  onAmptalkToggleChange(event: MatSlideToggleChange, slider: MatSlideToggle) {
    if (event.checked) {
      this.openAmptalkIntegrationDialog(slider)
    } else {
      this.openAmptalkResetWarningDialog(slider)
    }
  }

  onUpdateAmptalkIntegration(slider: MatSlideToggle) {
    this.openAmptalkIntegrationDialog(slider)
  }

  openAmptalkSettingDialog() {
    this.dialog.open<SettingDialogComponent, AutoAssessmentSettingDialogParam>(
      SettingDialogComponent,
      {
        id: 'amptalk-setting-dialog',
        disableClose: true,
        panelClass: ['unset-mat-dialog-padding'],
        enterAnimationDuration: '0ms',
        data: {
          connectService: ConnectService.AmpTalk,
          enableWordFilter: false,
        },
      },
    )
  }

  private openAmptalkIntegrationDialog(slider: MatSlideToggle) {
    this.dialog
      .open<AmptalkIntegrationComponent, AmptalkIntegrationData>(
        AmptalkIntegrationComponent,
        {
          disableClose: true,
          panelClass: 'unset-mat-dialog-padding',
          data: {
            apiKey: this.amptalkSetting.apiKey,
            authKey: this.amptalkSetting.authKey,
          },
        },
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          if (!this.isAmptalkIntegrationEnabled) {
            slider.checked = false
          }
          return
        }
        this.isAmptalkIntegrationEnabled = true
        this.hasAmptalkIntegrationError = false
        this.amptalkSetting.apiKey = result.apiKey
        this.amptalkSetting.authKey = result.authKey
        this.dialog.open(IntegrationSuccessComponent, {
          data: { serviceName: 'amptalk' },
        })
      })
  }

  private openAmptalkResetWarningDialog(slider: MatSlideToggle) {
    this.dialog
      .open(IntegrationResetWarningComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result === 'reset') {
            return this.externalIntegrationService.disconnectAmptalk().pipe(
              tap(() => {
                slider.checked = false
                this.initializeAmptalkIntegration()
              }),
              catchError(() => {
                slider.checked = true
                return of(null)
              }),
            )
          } else {
            slider.checked = true
            return of(null)
          }
        }),
      )
      .subscribe()
  }

  private initializeAmptalkIntegration() {
    this.isShowError = false
    this.isAmptalkIntegrationEnabled = false
    this.hasAmptalkIntegrationError = false
    this.amptalkSetting = {
      apiKey: '',
      authKey: '',
    }
  }

  async onZoomToggleChange(
    event: MatSlideToggleChange,
    slider: MatSlideToggle,
  ) {
    if (event.checked) {
      this.isZoomIntegrationEnabled = true
      const code = await this.openZoomAuthPopup()

      if (code == null) {
        // codeが取得できなかった場合のエラーハンドリング
        this.isZoomIntegrationEnabled = false
        this.snackBar.open('Zoom認証がキャンセルされました。', '閉じる')
        return
      }

      this.externalIntegrationService
        .saveZoomToken(code)
        .pipe(
          tap(() => {
            this.isZoomIntegrationEnabled = true
            this.hasZoomIntegrationError = false
            this.dialog.open(IntegrationSuccessComponent, {
              data: { serviceName: 'zoom' },
            })
          }),
          catchError(() => {
            this.isZoomIntegrationEnabled = false
            this.snackBar.open('Zoomの連携に失敗しました。', '閉じる')
            return of(null)
          }),
        )
        .subscribe()
    } else {
      this.openZoomResetWarningDialog(slider)
    }
  }

  openZoomSettingDialog() {
    this.dialog.open<SettingDialogComponent, AutoAssessmentSettingDialogParam>(
      SettingDialogComponent,
      {
        id: 'zoom-setting-dialog',
        disableClose: true,
        panelClass: ['unset-mat-dialog-padding'],
        enterAnimationDuration: '0ms',
        data: {
          connectService: ConnectService.Zoom,
          enableWordFilter: true,
        },
      },
    )
  }

  private openZoomAuthPopup(): Promise<string | null> {
    const clientId = environment.zoomClientId
    const redirectUri = environment.baseUrl + 'admin/external-integration'

    const zoomAuthUrl = `https://zoom.us/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}`

    return new Promise<string | null>((resolve) => {
      const popup = window.open(zoomAuthUrl, '_blank', 'width=600,height=600')

      const timer = setInterval(() => {
        if (!popup || popup.closed) {
          clearInterval(timer)
          return resolve(null)
        }

        if (popup.location.href.includes('/external-integration')) {
          const url = new URL(popup.location.href)
          const code = url.searchParams.get('code')

          popup.close()
          clearInterval(timer)
          return resolve(code)
        }
      }, 1000)
    })
  }

  private openZoomResetWarningDialog(slider: MatSlideToggle) {
    this.dialog
      .open(IntegrationResetWarningComponent, {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result === 'reset') {
            return this.externalIntegrationService.disconnectZoom().pipe(
              tap(() => {
                slider.checked = false
                this.initializeZoomIntegration()
              }),
              catchError(() => {
                slider.checked = true
                return of(null)
              }),
            )
          } else {
            slider.checked = true
            return of(null)
          }
        }),
      )
      .subscribe()
  }

  private initializeZoomIntegration() {
    this.isShowError = false
    this.isZoomIntegrationEnabled = false
    this.hasZoomIntegrationError = false
  }
}
