import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

@Component({
  selector: 'app-integration-reset-warning',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './integration-reset-warning.component.html',
  styleUrls: ['./integration-reset-warning.component.scss'],
})
export class IntegrationResetWarningComponent {
  constructor(
    private dialogRef: MatDialogRef<IntegrationResetWarningComponent>,
  ) {}

  cancel(): void {
    this.close()
  }

  reset(): void {
    this.dialogRef.close('reset')
  }

  close(): void {
    this.dialogRef.close()
  }
}
