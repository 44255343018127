import { AsyncPipe, CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu'
import { NavigationEnd, Router, RouterLink } from '@angular/router'

import { tap } from 'rxjs'

import { AuthService } from '../../services/auth.service'
import { UserInfo } from '../../services/user/user.service'
import { UserStore } from '../../stores/user.store'
import {
  UserService,
  PrivacySettingType,
} from '../../services/user/user.service'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    AsyncPipe,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    RouterLink,
    CommonModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  user: UserInfo | null = null
  privacySetting: PrivacySettingType | undefined = undefined

  currentPage: 'HOME' | 'USER_LIST' | 'ASSESSMENTS' | 'DASHBOARD' | 'ADMIN' =
    'HOME'

  constructor(
    private authService: AuthService,
    private router: Router,
    private userStore: UserStore,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userStore
      .getOneSelf({ useCache: true })
      .pipe(
        tap((user) => {
          this.user = user
        }),
      )
      .subscribe()

    this.userService
      .getAdminOrg()
      .pipe(
        tap((adminOrg) => {
          this.privacySetting = adminOrg.privacySetting
        }),
      )
      .subscribe()

    this.currentPage = this.getCurrentPage(this.router.url)
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = this.getCurrentPage(this.router.url)
      }
    })
  }

  logout = () => {
    console.log('Logged out')
    return this.authService.logout().finally(() => {
      // use browser location to clear all inner states (stores, services, etc)
      window.location.href = '/login'
    })
  }

  supportFormLink() {
    return `https://docs.google.com/forms/d/e/1FAIpQLSerSFSrjHhrBffOmJpfalaYjKwm486zijPP1dkEzQbb_W9L8A/viewform?entry.23212524=${encodeURIComponent(this.user?.email ?? '')}`
  }

  hasMember() {
    return this.isEnablerOrAdmin() || this.user?.isManager
  }

  isAdmin() {
    return this.user?.roles.some((role) => role.code === 'SUPER_ADMIN')
  }

  isEnablerOrAdmin() {
    return this.user?.roles.some(
      (role) => role.code === 'ENABLER' || role.code === 'SUPER_ADMIN',
    )
  }

  canSeeMemberInfo() {
    return (
      this.privacySetting === 'FULLY_OPEN' ||
      this.privacySetting === 'PARTIALLY_OPEN'
    )
  }

  private getCurrentPage(
    url: string,
  ): 'HOME' | 'USER_LIST' | 'ASSESSMENTS' | 'DASHBOARD' | 'ADMIN' {
    if (url.includes('admin')) {
      return 'ADMIN'
    }
    if (url.includes('users')) {
      return 'USER_LIST'
    }
    if (url.includes('assessments')) {
      return 'ASSESSMENTS'
    }
    if (url.includes('dashboard')) {
      return 'DASHBOARD'
    }

    return 'HOME'
  }
}
