<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon class="success">check_circle_outline</mat-icon
      >連携設定が完了しました
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <div class="success-message">
      <p>
        {{
          data.serviceName
        }}との連携設定が正常に完了しました。アセスメントの自動連携はアセスメント設定保存後に実行されます。
      </p>
      <p class="strong">続いて、アセスメント設定を進めてください。</p>
    </div>
  </div>
  <div class="footer">
    <button rectangle variant="primary" (click)="this.close()">OK</button>
  </div>
</div>
