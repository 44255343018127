import { CommonModule } from '@angular/common'
import { Component, Inject, Input, OnInit } from '@angular/core'

import { MatIcon } from '@angular/material/icon'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { MatTooltip } from '@angular/material/tooltip'

import { finalize, forkJoin } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'

import { ButtonComponent } from '../../../../components/button/button.component'
import {
  SelectComponent,
  Option,
} from '../../../../components/select/select.component'
import {
  MultipleSelectComponent,
  Item,
} from '../../../../components/multiple-select/multiple-select.component'
import { AvatarComponent } from '../../../../components/avatar/avatar.component'
import { EllipsisTooltipDirective } from '../../../../directives/ellipsis-tooltip.directive'

import {
  ExternalIntegrationService,
  SaveAutoAssessmentSettingParam,
} from '../../../../services/external-integration/external-integration.service'

import { SkillMap } from '../../../../services/assessment/assessment.mapping'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog'
import { AssessmentSettingSaveErrorComponent } from '../messages/assessment-setting-save-error/assessment-setting-save-errorcomponent'
import { UserForAdmin } from '../../../../services/user/user.mapping'
import { AssessmentStore } from '../../../../stores/assessment.store'
import { UserStore } from '../../../../stores/user.store'
import { LoadingComponent } from '../../../../components/loading/loading.component'
import { ConnectService } from '../../../../../_generated/graphql'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'

export interface AutoAssessmentSettingDialogParam {
  connectService: ConnectService
  enableWordFilter: boolean
}

interface UserRow {
  selected: boolean
  id: string
  name: string
  email: string
  thumbnailPath: string | null
  departmentName: string
  reportTo: string
  remainingHours: number | null
  hasLicense: boolean
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatCheckbox,
    MatTableModule,
    MatTooltip,
    MatFormFieldModule,
    MatSelectModule,
    ButtonComponent,
    SelectComponent,
    MultipleSelectComponent,
    AvatarComponent,
    EllipsisTooltipDirective,
    LoadingComponent,
  ],
  templateUrl: './setting-dialog.component.html',
  styleUrl: './setting-dialog.component.scss',
})
export class SettingDialogComponent implements OnInit {
  isShowError = false
  isLoading = false

  displayedColumns: string[] = [
    'select',
    'userName',
    'userDepartmentName',
    'reportTo',
    'remainingHours',
    'license',
  ]
  users: UserRow[] = []

  id: string | undefined = undefined
  syncStartDate: Date | undefined = undefined
  skillMaps: SkillMap[] = []
  skillMapOptions: Option[] = []
  selectedSkillMap: Option = { id: '', name: '' }
  phaseItems: Item[] = []
  minTransferDurationMinutes = ''

  recordingTargetOptions: Option[] = [
    { id: 'whiteListItem', name: '次の文字列を含む' },
    { id: 'blackListItem', name: '次の文字列を含まない' },
  ]

  recordingTargets: { selectedOption: Option; keyword: string }[] = []

  recordingTargetInputSelect: Option = {
    id: 'whiteListItem',
    name: '次の文字列を含む',
  }
  recordingTargetInputKeyword = ''
  isRecordingTargetInputKeywordError = false

  displayConnectServiceName: string = ''

  @Input() param!: AutoAssessmentSettingDialogParam

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SettingDialogComponent>,

    private snackBar: MatSnackBar,

    private userStore: UserStore,
    private assessmentStore: AssessmentStore,
    private externalIntegrationService: ExternalIntegrationService,

    @Inject(MAT_DIALOG_DATA)
    public data: AutoAssessmentSettingDialogParam,
  ) {}

  ngOnInit() {
    this.isLoading = true

    // 表示するサービス名のマッピング
    const connectServiceToDisplayNameMap: Record<ConnectService, string> = {
      [ConnectService.Zoom]: 'zoom',
      [ConnectService.AmpTalk]: 'amptalk',
    }
    this.displayConnectServiceName =
      connectServiceToDisplayNameMap[this.data.connectService]

    const getAutoAssessmentSetting = this.externalIntegrationService
      .getAutoAssessmentSetting(this.data.connectService)
      .pipe(
        tap((setting) => {
          if (setting === null) {
            return
          }

          this.id = setting.id
          this.minTransferDurationMinutes =
            setting.minTransferDurationSeconds !== null
              ? String(setting.minTransferDurationSeconds / 60)
              : ''
          this.syncStartDate = setting.syncStartDate

          const targetUsers = setting.targetUsers.map((user) => ({
            ...user,
            hasLicense: user.license !== null,
          }))
          targetUsers.forEach((user) => {
            const row = this.users.find((row) => row.email === user.email)
            if (row) {
              row.selected = true
            }
          })

          this.changeSkillMap(
            this.skillMapOptions.find(
              (op) => op.id === setting.targetSkillMap.id,
            ) ?? { id: '', name: '' },
          )

          setting.targetPhaseIds.forEach((phaseId) => {
            const phaseItem = this.phaseItems.find(
              (item) => item.id === phaseId,
            )
            if (phaseItem) {
              phaseItem.checked = true
            }
          })

          if (this.data.enableWordFilter) {
            setting.whiteList.forEach((keyword) => {
              const option = this.recordingTargetOptions.find(
                (opt) => opt.id === 'whiteListItem',
              )
              if (option) {
                this.recordingTargets.push({
                  selectedOption: option,
                  keyword: keyword,
                })
              }
            })
            setting.blackList.forEach((keyword) => {
              const option = this.recordingTargetOptions.find(
                (opt) => opt.id === 'blackListItem',
              )
              if (option) {
                this.recordingTargets.push({
                  selectedOption: option,
                  keyword: keyword,
                })
              }
            })
          }
        }),
      )

    forkJoin([
      this.assessmentStore.getSkillMaps().pipe(
        tap((skillMaps) => {
          this.skillMaps = skillMaps
          this.skillMapOptions = skillMaps.map((skillMap) => ({
            id: skillMap.id,
            name: skillMap.name,
          }))
        }),
      ),
      this.userStore.searchUsersForAdmin(null, []).pipe(
        tap((users) => {
          this.users = users.map((user) => this.toUserRow(user))
        }),
      ),
    ])
      .pipe(
        switchMap(() => {
          return getAutoAssessmentSetting
        }),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe()
  }

  onAssessmentSettingSave() {
    this.isShowError = true

    if (
      !this.skillMapSelected() ||
      !this.phaseSelected() ||
      !this.userSelected() ||
      !this.isValidMinTransferDuration()
    ) {
      this.openAssessmentSettingSaveErrorDialog()
      return
    }

    this.isLoading = true

    this.externalIntegrationService
      .saveAssessmentSetting(
        this.getSaveAutoAssessmentSettingParam(),
        this.data.connectService,
      )
      .pipe(
        tap(() => {
          this.snackBar.open(
            '設定が正常に保存されました。設定に基づいて、今後のアセスメントや同期が実行されます。',
            '閉じる',
          )
          this.dialogRef.close()
        }),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe()
  }

  changeSkillMap(option: Option): void {
    this.selectedSkillMap = this.skillMapOptions.find(
      (op) => op.id === option.id,
    ) ?? { id: '', name: '' }
    this.phaseItems =
      this.skillMaps
        .find((skillMap) => skillMap.id === option.id)
        ?.phases.map((phase) => ({
          id: phase.id,
          label: phase.name,
          checked: false,
        })) ?? []
  }

  changeSelectedPhases(items: Item[]): void {
    this.phaseItems = items
  }

  changeMinimumIntegrationDuration(event: Event): void {
    function toHalfWidth(str: string) {
      return str.replace(/[０-９]/g, function (ch) {
        return String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
      })
    }
    this.minTransferDurationMinutes = toHalfWidth(
      (event.target as HTMLInputElement).value,
    )
  }

  removeRecordingTarget(index: number) {
    this.recordingTargets.splice(index, 1)
  }

  onChangeRecordingTarget(index: number, option: Option) {
    this.recordingTargets[index].selectedOption = option
  }

  onKeywordChange(index: number, event: Event): void {
    const inputElement = event.target as HTMLInputElement
    if (inputElement) {
      this.recordingTargets[index].keyword = inputElement.value
    }
  }

  onChangeRecordingTargetInputSelect(option: Option) {
    this.recordingTargetInputSelect = option
  }

  onChangeRecordingTargetInputKeyword(event: Event) {
    const inputEl = event.target as HTMLInputElement
    this.recordingTargetInputKeyword = inputEl.value

    if (this.isRecordingTargetInputKeywordError && inputEl.value.trim()) {
      this.isRecordingTargetInputKeywordError = false
    }
  }

  onAddRecordingTarget() {
    const keyword = this.recordingTargetInputKeyword.trim()
    if (!keyword) {
      this.isRecordingTargetInputKeywordError = true
      return
    }

    this.recordingTargets.unshift({
      selectedOption: this.recordingTargetInputSelect,
      keyword,
    })

    this.recordingTargetInputKeyword = ''
    this.isRecordingTargetInputKeywordError = false
  }

  get checkedCount(): number {
    return this.users.filter((row) => row.selected)?.length
  }
  get checkedState(): 'CHECKED' | 'UNCHECKED' | 'INDETERMINATE' {
    const checkedCount =
      this.users.filter((assessment) => assessment.selected)?.length ?? 0
    if (checkedCount === 0) {
      return 'UNCHECKED'
    } else if (checkedCount === this.users.length) {
      return 'CHECKED'
    } else {
      return 'INDETERMINATE'
    }
  }

  skillMapSelected(): boolean {
    return this.selectedSkillMap.id !== ''
  }

  phaseSelected(): boolean {
    return this.phaseItems.filter((item) => item.checked).length > 0
  }

  isValidMinTransferDuration(): boolean {
    return /^[0-9]*$/.test(this.minTransferDurationMinutes)
  }

  userSelected(): boolean {
    return this.users.filter((user) => user.selected).length > 0
  }

  checkAllUsers = (checked: boolean) => {
    this.users.forEach((user) => {
      if (checked && !user.selected) {
        if (user.hasLicense) {
          user.selected = checked
        }
      } else if (!checked && user.selected) {
        user.selected = checked
      }
    })
  }

  checkChanged = (user: UserRow) => {
    user.selected = !user.selected
  }

  closeSettingDialog() {
    this.dialogRef.close()
  }

  private openAssessmentSettingSaveErrorDialog() {
    this.dialog.open(AssessmentSettingSaveErrorComponent, {
      disableClose: true,
      panelClass: 'unset-mat-dialog-padding',
    })
  }

  private getSaveAutoAssessmentSettingParam(): SaveAutoAssessmentSettingParam {
    const phaseIds: string[] = []
    const whiteList: string[] = []
    const blackList: string[] = []

    this.recordingTargets.forEach((target) => {
      const trimmedKeyword = target.keyword.trim()
      if (trimmedKeyword === '') {
        return
      }

      if (target.selectedOption.id === 'whiteListItem') {
        whiteList.push(trimmedKeyword)
      } else if (target.selectedOption.id === 'blackListItem') {
        blackList.push(trimmedKeyword)
      }
    })

    this.phaseItems.forEach((item) => {
      if (item.checked && item.id) {
        phaseIds.push(item.id)
      }
    })
    const targetUserIds = this.users
      .filter((user) => user.selected)
      .map((user) => user.id)
    return {
      id: this.id,
      syncStartDate: this.syncStartDate ?? new Date(),
      skillMapId: this.selectedSkillMap.id,
      phaseIds: phaseIds,
      targetUserIds: targetUserIds,
      minTransferDurationSeconds:
        this.minTransferDurationMinutes === ''
          ? null
          : Number(this.minTransferDurationMinutes) * 60,
      whiteList,
      blackList,
    }
  }

  private toUserRow(user: UserForAdmin): UserRow {
    let remainingHours = null
    if (user.license) {
      const remainingSeconds =
        user.license.userMonthlyLimitSeconds -
        user.license.usedSeconds +
        user.license.extraChargeLimitSeconds
      remainingHours = Math.floor(remainingSeconds / 3600)
    }

    return {
      selected: false,
      id: user.id,
      name: user.name,
      email: user.email,
      thumbnailPath: user.thumbnailPath || null,
      departmentName: user.departmentName,
      reportTo: user.reportTo?.name || '',
      remainingHours: remainingHours,
      hasLicense: user.license !== null,
    }
  }
}
