import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

@Component({
  selector: 'app-integration-success',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './integration-success.component.html',
  styleUrls: ['./integration-success.component.scss'],
})
export class IntegrationSuccessComponent {
  constructor(
    private dialogRef: MatDialogRef<IntegrationSuccessComponent>,

    @Inject(MAT_DIALOG_DATA) public data: { serviceName: 'amptalk' | 'zoom' },
  ) {}

  close(): void {
    this.dialogRef.close()
  }
}
